import React from "react"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Navbar from "@components/navbar"
import RemoteForm from "@components/remoteForm"

const RemotePage = () => (
  <Layout>
    <SEO title="Remote" />
    <div className="page-layout remote-page">
      <section className="intro intro--no-waves">
        <div className="container">
          <Navbar />
          <div className="container-contact container text-left">
            <h1 className="labs-title">
              Remote Teams: <br />
              Your New Reality
              <span className="labs-blue">.</span>
            </h1>
          </div>
        </div>
      </section>
      <div className="intro-waves"></div>
      <section className="remote-page wave-wrapper">
        <div className="dark-bg page-container">
          <div className="py-2 container text-left">
            <div className="row justify-content-center remote-page-text">
              <div className="col-12 col-lg-8">
                <i>Labs42 makes distributed teams work</i>
                <p>
                  As teams embrace the new reality of remote work, organizations
                  must quickly mobilize to keep key initiatives on track.
                  Organizations are using the Labs42 solution to create teams
                  and remotely manage talent.
                </p>
                <p>
                  Labs42 can help you spin up a team today of top digital
                  talent.
                </p>
                <ul>
                  <li>
                    On-demand talent of developers and designers with unique
                    skill sets
                  </li>
                  <li>
                    Elastic resourcing (fractional to full-time, just-in-time
                    for any role, any phase)
                  </li>
                  <li>
                    Manages productivity and mitigates risk (Peer-verified
                    delivery and management)
                  </li>
                </ul>
                <i>Top digital talent available to help today</i>
                <p>
                  If you’re actively seeking key roles like Front-end Web/Mobile
                  developers, Back-end developers or Architects, Labs42 can help
                  immediately.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="contact-us" className="contactus-wrapper">
          <div className="container">
            <h2 className="heading">
              Talk to an Innovation Advisor
              <span className="labs-blue heading-dot"> .</span>
            </h2>
            <RemoteForm />
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default RemotePage
