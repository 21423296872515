import React from "react"
import { Row, Col, Form } from "react-bootstrap"
import { Button } from "react-bootstrap"

const Message = ({ content, success }) => (
  <div
    className="success-form-message p-3 mt-3"
    style={success ? { visibility: "visible" } : { visibility: "hidden" }}
  >
    <p className="m-0">{content}</p>
  </div>
)

const validateEmail = email => {
  var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const RemoteForm = () => {
  const [state, setState] = React.useState({})
  const [touched, setTouched] = React.useState({})
  const [success, setSuccess] = React.useState(false)

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "remote",
        ...state,
      }),
    }).then(() => setSuccess(true))
    setState({})
    setTouched({})
    setTimeout(() => {
      setSuccess(false)
    }, 3000)
    e.preventDefault()
  }

  const onChange = e => setState({ ...state, [e.target.name]: e.target.value })

  const onBlur = field => setTouched({ ...touched, [field]: true })

  return (
    <Form
      id="remote-form"
      name="remote"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      method="post"
    >
      <Row className="contact-us-form justify-content-center">
        <Col xs={12} lg={8} className="height-control">
          <Row>
            <Form.Control
              type="text"
              placeholder="First Name"
              required
              name="name"
              onChange={onChange}
              onBlur={() => onBlur("name")}
              isInvalid={touched.name && !state.name}
              isValid={touched.name && state.name}
              value={state.name || ""}
            />
          </Row>
          <Row>
            <Form.Control
              type="text"
              placeholder="Last Name"
              required
              name="lastName"
              onChange={onChange}
              onBlur={() => onBlur("lastName")}
              isInvalid={touched.lastName && !state.lastName}
              isValid={touched.lastName && state.lastName}
              value={state.lastName || ""}
            />
          </Row>
          <Row>
            <Form.Control
              type="text"
              placeholder="Company"
              required
              name="company"
              onChange={onChange}
              onBlur={() => onBlur("company")}
              isInvalid={touched.company && !state.company}
              isValid={touched.company && state.company}
              value={state.company || ""}
            />
          </Row>
          <Row>
            <Form.Control
              type="email"
              placeholder="E-mail address"
              required
              name="email"
              onChange={onChange}
              onBlur={() => onBlur("email")}
              isInvalid={touched.email && !validateEmail(state.email)}
              isValid={touched.email && validateEmail(state.email)}
              value={state.email || ""}
            />
          </Row>
          <Row>
            <Form.Control
              required
              placeholder="Phone"
              name="phone"
              onChange={onChange}
              onBlur={() => onBlur("phone")}
              isInvalid={touched.phone && !state.phone}
              isValid={touched.phone && state.phone}
              value={state.phone || ""}
            />
          </Row>
          <div className="contact-submit-wrapper contact-us-form text-right">
            <div className="text-right">
              <Button type="submit">
                Contact Me
                <svg
                  enableBackground="new 0 0 32 32"
                  height="32px"
                  version="1.1"
                  viewBox="0 0 32 32"
                  width="32px"
                  space="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  className="arrow-icon"
                >
                  <path
                    clipRule="evenodd"
                    d="M32,16.009c0-0.267-0.11-0.522-0.293-0.714  l-9.899-9.999c-0.391-0.395-1.024-0.394-1.414,0c-0.391,0.394-0.391,1.034,0,1.428l8.193,8.275H1c-0.552,0-1,0.452-1,1.01  s0.448,1.01,1,1.01h27.586l-8.192,8.275c-0.391,0.394-0.39,1.034,0,1.428c0.391,0.394,1.024,0.394,1.414,0l9.899-9.999  C31.894,16.534,31.997,16.274,32,16.009z"
                    fill="#fff"
                    fillRule="evenodd"
                    id="Arrow_Forward"
                  ></path>
                </svg>
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Message
        success={success}
        content="Thank you for contacting us. We will get back to you soon!"
      />
    </Form>
  )
}

export default RemoteForm
